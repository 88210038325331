.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.sidebar-collapse .brand-image.img-circle {
  width: 2.1rem;
}
.brand-image.img-circle {
  width: 7rem;
  align-items: center;
}
.user-panel .image {
  padding-left: 0px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chart-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart-container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 400px;
  background-color: #fff;
}

.dashboard-table-wrapper > .card-body {
  padding: 0;
}

.custom-tooltip {
  background: #ffffff99;
  border: 1px solid gray;
  padding: 0px 10px;
}

.column-selector {
  width: 800px;
  height: 400px;
  margin-right: 50px;
}

.tab-container {
  display: flex;
  flex-wrap: wrap;
}

.column-container {
  width: 33.33%;
}
