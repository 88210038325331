input.form-control.tableInp[type=checkbox]
	{
		height: 16px;
		width: 100%;
		margin: 0;
		padding: 0;
	}
	.att .table td
	{
		vertical-align: middle;
	}
	tr th:not(:first-child)
	{
		padding: 10px 5px!important;
	}
	#pop_cal1,#atten_data,#pop_cal
	{
		table-layout: auto;
	}
.table-container{
  max-width: 100%;
  overflow-x: auto;
}  
.modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* Add other modal styles here */
  }
.table {
    border-color: #fbc9cb!important;
    margin-top: 15px!important;
  
    }
    .table td
		{
			padding: 6px 8px!important;
		}
		.redBg
		{
		  background-color: #f5d5d6;
		}
        .table-responsive
{
	overflow-x: auto;
}
.table thead,.table th
{
	background-color:#007bff;
	border-color: #ebe4f3!important;
}
.table thead th, .table th
{
	color: #fff;
	vertical-align: middle!important;
	padding: 10px 20px 10px 20px!important;
	border-bottom: 1px!important;
	white-space: nowrap;
	font-weight: 400!important;
	text-transform: uppercase;
	border-right: 1px solid #ebe4f3!important;
}
.table tr td:first-child
{
	border-left: 1px solid #ebe4f3!important;
}
.table td
{
	border:none!important;
	border-right: 1px solid #ebe4f3!important;
	border-bottom: 1px solid #ebe4f3!important;
	white-space: nowrap;
	position: relative;
}
.att .table td
{
	position: initial;
}
.table td a
{
	color: #333;
}
.table
{
	border-color: #ebe4f3!important;
	margin-top: 15px!important;
}
.table-plus
{
	position: absolute;
	right: 5px;
	top: 25%;
	padding: 0px;
}
table.dataTable.no-footer
{
	border-bottom: none;
}
.table-striped>tbody>tr:nth-of-type(odd)
{
	background-color: #f5d5d6;
}
.modal{
	background-color: rgba(0, 0, 0, 0.5);
  }
 
